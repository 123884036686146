// Copyright 2021 The Casdoor Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {createButton} from "react-social-login-buttons";

function Icon({width = 24, height = 24, color}) {
  return <img src="https://id.itmo.ru/static/services/profile/icon.svg" alt="Авторизоваться через ID.ITMO" />;
}

const config = {
  text: "Авторизоваться через ID.ITMO",
  icon: Icon,
  iconFormat: name => `fa fa-${name}`,
  style: {
    background: "#ffffff",
    color: "#000000",
    padding: "0 10px",
    borderRadius: "8px",
    border: "1px solid #081f57",
    height: "40px",
    width: "100%",
    margin: 0,
    fontFamily: "-apple-system, 'system-ui', 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
    fontSize: "16px",
  },
  activeStyle: {background: "#eff0ee"},
};

const GitHubLoginButton = createButton(config);

export default GitHubLoginButton;
